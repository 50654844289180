import Vue, { provide } from 'vue'

import ElementUI, { MessageBox } from 'element-ui'
import './base.css'
// import 'element-ui/lib/theme-chalk/index.css'
import './theme/index.css'
import './tw.css'
import formMixin from 'shared/mixins/formMixin'
import Pagination from 'shared/components/Pagination'
import PageList from 'shared/pages/List'
import BreadCrumb from 'shared/components/BreadCrumb'
import PageModel from 'shared/pages/Model'
import ClipLoader from './components/ClipLoader'

import FormItem from 'shared/pages/FormItem'
import PageDetail from 'shared/pages/Detail'
import DetailItem from 'shared/components/DetailItem'
import SubmitItem from 'shared/components/SubmitItem'
import PageForm from 'shared/pages/Form'
import App from './App.vue'
import * as Sentry from '@sentry/vue'

import RouteWrapper from './components/RouteWrapper'
import Card from './components/Card.vue'
import SearchButtons from './components/SearchButtons'
import Header from './components/Header'
import Spacer from './components/Spacer.vue'
import { api, apiPath } from './api'
import * as consts from './consts'

Vue.config.productionTip = false

import store from './store'
import router from './router'
Vue.component('Loading', {
  render() {
    return (
      <div class="flex mt-8 justify-center">
        <ClipLoader color="#5da2ff" />
      </div>
    )
  },
})

Vue.mixin(formMixin)
Vue.use(ElementUI, { size: 'small' })
Vue.prototype.$confirm = (...args) => {
  return new Promise(function (resolve) {
    MessageBox.confirm(...args)
      .then(resolve)
      .catch(console.log)
  })
}

Vue.component('Pagination', Pagination)
Vue.component('Card', Card)
Vue.component('SearchButtons', SearchButtons)
Vue.component('Header', Header)
Vue.component('Spacer', Spacer)
Vue.component('FormItem', FormItem)
Vue.component('DetailItem', DetailItem)
Vue.component('PageList', PageList)
Vue.component('BreadCrumb', BreadCrumb)
Vue.component('PageModel', PageModel)
Vue.component('PageDetail', PageDetail)
Vue.component('PageForm', PageForm)
Vue.component('Route', RouteWrapper)

Vue.component('SubmitItem', SubmitItem)
if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: 'https://10773f06ecb2441a8782380b15853e81@o1058326.ingest.sentry.io/6051269',

    ignoreErrors: [
      /4\d{2}$/,
      'Network Error',
      /5\d{2}$/,
      /Navigation/,
      'ChunkLoadError', // 由于 webpack 的 code split，可能会出现加载动态的bundle网络错误，已经有外层的errorboudnary处理了

      /Loading CSS chunk \d+ failed/, // css chunk 加载失败，属于网络问题，不需要处理
    ],
    integrations: [
      Sentry.browserTracingIntegration({
        tracingOrigins: [
          'localhost',
          'admin.suwenxuan.cn',
          'admin.suwenxuan.net',
          /^\//,
        ],
      }),
      Sentry.replayIntegration(),
    ],

    tracesSampleRate: 0.01,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    release:
      (process.env.ENV == 'staging' ? 'staging@' : 'production@') +
      require('../package.json')['version'],
  })
}

Vue.mixin({
  data() {
    return {
      api,
      apiPath,
      consts,
    }
  },
})

new Vue({
  setup() {
    provide('store', store)
  },
  store,
  router,
  el: '#app',
  render: h => h(App),
})
